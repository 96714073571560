@import url('https://fonts.googleapis.com/css2?family=Inconsolata&family=Press+Start+2P&family=Roboto+Mono:wght@100;200;300;400;500;700&display=swap');
/* 
font-family: 'Press Start 2P', cursive;
font-family: 'Roboto Mono', monospace; 
*/
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body{
  background-color: #191e1f;
  color: #00ba0a;
  /* font-family: 'Press Start 2P', cursive; */
  font-family: 'Roboto Mono', monospace; 

}
.silly-goose{
  position: absolute; 
  left: 0; 
  right: 0; 
  top: 50%;
  margin-left: auto; 
  margin-right: auto; 
  width: 300px;
}
.hidden{
  display: none;
}
.terminal-window-full{
  position: absolute;
  width: 100vw;
  height: 100vh;
  border: rgb(255, 255, 255) solid 1px;
  border-radius: 5px;
  background-color: #030303;
  box-shadow: 0px 0px 5px #00ba0a;;

}
.nav-icon{
  max-width: 100%;
  width: 1rem;
  border: solid rgb(230, 230, 230) 1px;
  background-color: rgb(179, 179, 179);
  cursor: pointer;
  margin-right: 2px;
  margin-left: 2px;  
  transition: background-color 250ms ease-in-out;
}

.nav-icon:hover{
  background-color: white;
  transition: background-color 250ms ease-in-out;
}

.terminal-window{
  position: absolute;
  right: 25%;
  top: 10%;
  width: 50%;
  height: 80%;
  border: rgb(255, 255, 255) solid 1px;
  border-radius: 5px;
  background-color: #030303;
  box-shadow: 0px 0px 5px #00ba0a;;
}

.terminal-bar{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 5px;

  width: 100%;
  height: 5%;
  background-color: gray;
  border-radius: 5px 5px 0px 0px;
}

.terminal-content{
  display: flex;
  height: 90%;
  width: 100%;
  flex-direction: column;
  overflow: auto; 
  padding: 10px;
}

.terminal-header{
  margin-bottom: 10%;
}

input{
  width: 60%;
  background-color: #030303;
  border: none;
  color: #00ba0a;
   font-family: 'Inconsolata', monospace;
   font-size: 18px;
}

input:focus{
  outline: none;
}

.payload-header{
  text-align: center;
}
.payload-body{
  padding-left: 10%;
  padding-right: 10%;
}

.payload-body-skills-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.payload-body-skills{
  padding: 20px;
}

ul{
  list-style-type: square;
}

a{
  color: #00ba0a;
  text-decoration: underline;
}

.minimized-container{
  font-size: 10px;
  position: absolute;
  bottom: 0;
  left: 50px;
  border: 1px solid black;
  box-shadow: 0px 0px 5px black;
  background-color: #78716C;
  cursor: pointer;
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.minimized-container:hover{
  color: black;
  background-color: #D6D3D1;
}

@media all and (max-width: 540px){
  .terminal-window{
    position: absolute;
    right: 0;
    top: 10%;
    width: 100vw;
    height: 80%;
    border: rgb(255, 255, 255) solid 1px;
    border-radius: 5px;
    background-color: #030303;
    box-shadow: 0px 0px 5px #00ba0a;;
  }
  input{
    width: 40%;
    background-color: #030303;
    border: none;
    color: #00ba0a;
     font-family: 'Inconsolata', monospace;
     font-size: 18px;
  }
}